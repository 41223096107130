import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Thermal from '../views/products/Thermal.vue'
import ArtificialInsemination from '../views/products/ArtificialInsemination.vue'
import Diet from '../views/products/Diet.vue'
import Medical from '../views/products/Medical.vue'
import Others from '../views/products/Others.vue'
import Information from '../views/Information.vue'
import NotFound from '../views/NotFound.vue'

const routes = [
    {
        path: '/',
        name: 'Home',
        meta: { layout: 'home-layout' },
        component: Home
    },
    {
        path: '/products/thermal',
        name: 'Thermal',
        meta: { layout: 'default-layout' },
        component: Thermal
    },
    {
        path: '/products/artificial-insemination',
        name: 'ArtificialInsemination',
        meta: { layout: 'default-layout' },
        component: ArtificialInsemination
    },
    {
        path: '/products/diet',
        name: 'Diet',
        meta: { layout: 'default-layout' },
        component: Diet
    },
    {
        path: '/products/medical',
        name: 'Medical',
        meta: { layout: 'default-layout' },
        component: Medical
    },
    {
        path: '/products/others',
        name: 'Others',
        meta: { layout: 'default-layout' },
        component: Others
    },
    {
        path: '/information',
        name: 'Information',
        meta: { layout: 'default-layout' },
        component: Information
    },
    {
        path: '/:pathMatch(.*)',
        name: '404',
        component: NotFound
    }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
