<template>
    <ProductTop page="Thermal" />
    <ProductList page="Thermal" :nums="4" />
</template>

<script>
import ProductTop from '@/components/ProductTop.vue';
import ProductList from '@/components/ProductList.vue';
export default {
    // eslint-disable-next-line
    name: 'Thermal',
    components: {
        ProductTop,
        ProductList
    }
}
</script>
