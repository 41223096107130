<template>
    <div class="row info-top-banner d-none d-sm-block" :style="{ 'background-image': 'url(' + getImgUrl('Information.jpg') + ')' }">
        <div class="col-12 col-md-10 offset-md-2">
            <h1 class="align-bottom">Information</h1>
        </div>
    </div>
    <div class="row info-title">
        <div class="col-12 col-md-10 offset-md-2">
            <h2>Exhibition</h2>
        </div>

        <div class="col-12 col-md-8 offset-md-2">
            <div class="exhibition">
                <!-- <div v-for="i, index in 5" :key="index">
                    <img class="slide-img" :src="getImgUrl(`information/DM0${i}.jpg`)" alt="">
                    <div class="slide-count-wrap">
                        <span class="current">1</span> /
                        <span class="total">5</span>
                    </div>
                    <div class="download">
                        <a href="/file/MaxStockDM.pdf" target="_blank"><span>PDF Download</span> <img src="/images/information/download.jpg" alt=""></a>
                    </div>
                </div> -->

                <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-inner">
                        <div v-for="i, index in 5" :key="index" class="carousel-item" :class="{ active: i === 1 }">
                            <img :src="getImgUrl(`information/DM0${i}.jpg`)" class="d-block w-100" alt="...">
                        </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>
            </div>

            <div class="download">
                <a :href="getFileUrl('MaxStockDM.pdf').default" target="_blank"><span>PDF Download</span> <img :src="getImgUrl(`information/download.jpg`)" alt=""></a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    // eslint-disable-next-line
    name: 'Information',
    methods: {
        getImgUrl(img) {
            return require(`../assets/images/${img}`)
        },
        getFileUrl(file) {
            console.log(require(`../assets/file/${file}`))
            return require(`../assets/file/${file}`)
        }
    }
}
</script>

<style scoped lang="less">
.row {
    text-align: start;
}

.info-top-banner {
    height: 400px;
    position: relative;
    padding: 0;
    margin: 0;

    > div {
        position: absolute;
        bottom: 0;
    }

    h1 {
        color: white;
        font-size: 5.625em;
        text-transform: uppercase;
        margin-bottom: -20px;
    }
}

.info-title {
    padding: 0;
    margin: 0;

    h2 {
        font-size: 1.75em;
        font-family: 'SegoeUI';
        text-transform: uppercase;
        margin: 20px 0;
    }
}

.slide-img {
    width: 100%;
}

.slide-count-wrap {
    text-align: center;
}

// .slick-next::before {
//         background: url('../images/information/arrowR.jpg') no-repeat;
//         content: "";
//         display: block;
//         height: 50px;
//         width: 29px;
// }

// .slick-prev:before {
//         background: url('../images/information/arrowL.jpg') no-repeat;
//         content: "";
//         display: block;
//         height: 50px;
//         width: 29px;
//         margin-left: -15px;
// }

.slide-count-wrap {
    margin: 50px;
}

.download {
    float: right;

    a {
        color: #999;
        text-decoration: none;
    }

    img {
        display: inline-block;
        margin-top: -2px;
    }
}

.carousel-control-next {
    justify-content: end;
    right: -30px
}
.carousel-control-prev {
    justify-content: start;
    left: -30px
}
.carousel-control-prev-icon, .carousel-control-next-icon {
    filter: invert(80%);
}

@media screen and (max-width: 767px) {
    .slick-next, .slick-prev {
        display: none !important;
    }
}
</style>
