<template>
    <div>
        <img class="w-100" src="../../assets/images/index-top-banner.jpg" alt="" />
    </div>

    <Header />

    <div class="container-fluid">
        <div class="row">
            <slot />
        </div>
    </div>

    <Footer />
</template>

<script>
import Header from '../Header.vue'
import Footer from '../Footer.vue'

export default {
    name: 'HomeLayout',
    components: {
        Header,
        Footer
    }
}
</script>