<template>
    <div class="container">
        <div class="content">
            <div class="title">404 Not Found</div>
        </div>
    </div>
</template>

<style scoped>
.container {
    height: 50vh;
    color: #B0BEC5;
    display: table;
    font-weight: 100;
    font-family: 'Lato';
}

.content {
    text-align: center;
    display: table-cell;
    vertical-align: middle;
}

.title {
    font-size: 72px;
    margin-bottom: 40px;
}
</style>