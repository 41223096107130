<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-white py-0">
        <div class="container-fluid px-4 px-lg-0">
            <a class="navbar-brand pe-3 pt-3 pt-md-0" href="/">
                <img src="../assets/images/logo.jpg" alt="" />
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <!-- <li class="nav-item">
                        <router-link class="nav-link" to="">NEWS</router-link>
                    </li> -->
                    <li class="nav-item">
                        <router-link class="nav-link" to="/products/thermal">PRODUCTS</router-link>
                    </li>
                    <!-- <li class="nav-item">
                        <router-link class="nav-link" to="">ABOUT MAXSTOCK</router-link>
                    </li> -->
                    <li class="nav-item">
                        <router-link class="nav-link" to="/information">INFORMATION</router-link>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="mailto: maxstock.tw@gmail.com">CONTACT</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    // eslint-disable-next-line
    name: 'Header'
}
</script>

<style scoped lang="less">
.navbar-light .navbar-toggler {
    color: white;
}

.navbar .navbar-nav li {
    a {
        font-size: 1.15em;
        font-weight: bold;
        color: black;
    }

    a:hover {
        background-color: #f2f2f2;
    }
}

@media (min-width: 992px) {
    .container-fluid {
        width: auto;
    }

    .navbar .navbar-nav li a {
        height: 130px;
        line-height: 100px;
    }
}
</style>
