<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-8 offset-2 d-none d-md-block">
                <div class="row menu">
                    <div class="col-2 offset-1">
                        <h5>NEWS & EVENTS</h5>
                        <ul>
                            <li>Read More</li>
                        </ul>
                    </div>
                    <div class="col-2">
                        <h5>PRODUCTS</h5>
                        <ul>
                            <li>Thermal</li>
                            <li>Diet</li>
                            <li>Artificial Insemination</li>
                            <li>Medical</li>
                            <li>Cooling System</li>
                            <li>Others</li>
                        </ul>
                    </div>
                    <div class="col-2">
                        <h5>ABOUT MAXSTOCK</h5>
                        <ul>
                            <li>History</li>
                            <li>Faith</li>
                            <li>Innovation</li>
                        </ul>
                    </div>
                    <div class="col-2">
                        <h5>INFORMATION</h5>
                        <ul>
                            <li>Read More</li>
                        </ul>
                    </div>
                    <div class="col-2">
                        <h5>CONTACT</h5>
                        <ul>
                            <li>Company Info</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-12 copyright">
                Copyright © Maxstock All Rights Reserved.
            </div>
        </div>
    </div>
</template>

<script>
export default {
    // eslint-disable-next-line
    name: 'Footer',
}
</script>

<style scoped lang="less">
.menu {
    margin: 30px 0;
    display: flex;
    text-align: start;

    ul {
        list-style: none;
        padding: 0;
    }

    > div:not(:last-child) {
        padding: 0;
        margin-right: 10px;
        border-right: 2px solid gray;
    }
}

.copyright {
    text-align: center;
    margin: 40px auto;
}

.footer-inner {
    background-color: #e5e5e5;
}
</style>
