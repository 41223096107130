<template>
    <ProductTop page="Others" />
    <ProductList page="Others" :nums="7" />
</template>

<script>
import ProductTop from '@/components/ProductTop.vue';
import ProductList from '@/components/ProductList.vue';
export default {
    // eslint-disable-next-line
    name: 'Others',
    components: {
        ProductTop,
        ProductList
    }
}
</script>
