<template>
    <ProductTop page="Medical" />
    <ProductList page="Medical" :nums="24" />
</template>

<script>
import ProductTop from '@/components/ProductTop.vue';
import ProductList from '@/components/ProductList.vue';
export default {
    // eslint-disable-next-line
    name: 'Medical',
    components: {
        ProductTop,
        ProductList
    }
}
</script>
