import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueGoogleMaps from '@fawmi/vue-google-maps'

const app = createApp(App)
app.use(router)
app.use(VueGoogleMaps, {
  load: {
      key: 'AIzaSyBRfLAiZw36oobL28T5ZKE7auDmzli-YtQ',
  },
})
app.mount('#app')
