<template>
    <Header />

    <div class="container-fluid">
        <div class="row">
            <slot />
        </div>
    </div>

    <Footer class="footer-inner" />
</template>

<script>
import Header from '../Header.vue'
import Footer from '../Footer.vue'

export default {
    name: 'DefaultLayout',
    components: {
        Header,
        Footer
    }
}
</script>

<style scoped>
.footer-inner {
    background-color: #e5e5e5;
}
</style>
