<template>
    <div class="row product-top-banner d-none d-sm-block" :style="{ 'background-image': 'url(' + getImgUrl(page) + ')' }">
        <div class="col-12 col-md-10 offset-md-2">
            <h1 class="align-bottom">{{ page.replace('-', ' ') }}</h1>
        </div>
    </div>
    <div class="row product-title">
        <div class="col-12 col-md-10 offset-md-2">
            <ul>
                <li><a href="/products/thermal">Thermal</a></li>
                <li><a href="/products/diet">Diet</a></li>
                <li><a href="/products/artificial-insemination">Artificial Insemination</a></li>
                <li><a href="/products/medical">Medical</a></li>
                <!-- <li><a href="/products/cooling-system">Cooling System</a></li> -->
                <li><a href="/products/others">Others</a></li>
            </ul>
        </div>
        <div class="col-12 col-md-10 offset-md-2">
            <h2>{{ page.replace('-', ' ') }}</h2>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProductTop',
    props: ['page'],
    methods: {
        getImgUrl(page) {
            return require(`../assets/images/products/${page}.jpg`)
        }
    }
}
</script>

<style scoped lang="less">
.row {
    text-align: start;
}

.product-top-banner {
    height: 400px;
    position: relative;
    padding: 0;
    margin: 0;

    > div {
        position: absolute;
        bottom: 0;
    }

    h1 {
        color: white;
        font-size: 5.625em;
        text-transform: uppercase;
        margin-bottom: -20px;
    }
}

.product-title {
    ul {
        padding: 0;
        margin: 50px 0 30px 0;

        li {
            padding: 0 25px;
            display: inline-block;
            font-size: 1em;
            border-right: 1px solid #858585;
            text-transform: uppercase;
            letter-spacing: 1px;

            a {
                color: #333;
            }

            a:hover {
                color: #aaa;
                text-decoration: none;
            }
        }

        li:first-child {
            padding-left: 0;
        }
    }

    h2 {
        font-size: 1.75em;
        font-family: 'SegoeUI';
        text-transform: uppercase;
        margin: 20px 0;
    }
}

.product-image {
    margin-bottom: 50px;
    .image {
        width: 100%;
        background-color: #e5e5e5;
        text-align: center;

        img {
            padding: 10px;
        }
    }
}

@media screen and (max-width: 767px) {
    .product-title ul li {
        padding: 0;
        display: block;
        font-size: 1.1em;
        line-height: 1.5em;
        font-family: 'SegoeUI';
        border: none;
        text-transform: uppercase;
        letter-spacing: 1px;
    }
}
</style>