<template>
    <component :is="layout">
        <router-view/>
    </component>
</template>

<script>
import HomeLayout from './components/layout/Home.vue'
import DefaultLayout from './components/layout/Default.vue'

export default {
    name: 'App',
    components: {
        HomeLayout,
        DefaultLayout
    },
    computed: {
        layout () {
            return (this.$route.meta.layout || 'default-layout')
        }
    }
}
</script>

<style>
@font-face {
    font-family: 'SegoeUI';
    src: url('./assets/font/SegoeUI/SegoeUI.eot');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'BellMT';
    src: url('./assets/font/BellMT/BellMT.eot');
    font-weight: normal;
    font-style: normal;
}

#app {
  font-family: SegoeUI, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-size: 14px;
}

h3 {
  font-size: 24px;
}

h5 {
  font-size: 14px;
}

a {
    color: #333;
    text-decoration: none;
}

a:hover {
    letter-spacing: 1px;
    transition: all .5s ease;
}
</style>
