<template>
    <div class="row">
        <div class="col-12 col-md-8 offset-md-2">
            <div class="row product-list">
                <div v-for="i, index in nums" :key="index" class="col-12 col-md-4 product-image">
                    <div class="image">
                        <img :src="getImgUrl(`${page}/${i}`)" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProductList',
    props: ['page', 'nums'],
    methods: {
        getImgUrl(product) {
            return require(`../assets/images/products/${product}.png`)
        }
    }
}
</script>

<style scoped lang="less">
.product-image {
    margin-bottom: 50px;
    .image {
        width: 100%;
        height: 100%;
        background-color: #e5e5e5;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            padding: 10px;
        }
    }
}

@media screen and (max-width: 767px) {
    .product-image {
        margin-bottom: 10px;
    }

    .product-image .image {
        min-height: 180px;
    }
}
</style>