<template>
    <ProductTop page="Diet" />
    <ProductList page="Diet" :nums="9" />
</template>

<script>
import ProductTop from '@/components/ProductTop.vue';
import ProductList from '@/components/ProductList.vue';
export default {
    // eslint-disable-next-line
    name: 'Diet',
    components: {
        ProductTop,
        ProductList
    }
}
</script>
